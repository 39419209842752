import { Maybe } from '@/types';

export const baseSectionContainerStyles = {
	display: 'flex',
	flexDirection: 'column',
	gap: '1rem',
	marginBottom: '1.25rem',
	padding: '0 1.5rem 0 1.5rem',
};

export const titleStyles = (titleAlignment: Maybe<string>) => ({
	textAlign: titleAlignment?.toLowerCase() ?? 'center',
});

export const descriptionStyles = (descriptionAlignment: Maybe<string>) => ({
	textAlign: descriptionAlignment?.toLowerCase() ?? 'center',
});
